import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

import { PrivateRoute } from './auth/PrivateRoute';
import ScrollToTop from './components/shared/ScrollToTop';
const NavBar = lazy(() => import('./components/layout/NavBar'));

const HomePage = lazy(() => import('./pages/cms/HomePage'));
const AboutPage = lazy(() => import('./pages/cms/AboutPage'));
const NotFoundPage = lazy(() => import('./pages/cms/NotFoundPage'));
const PrivacyPage = lazy(() => import('./pages/cms/PrivacyPage'));
const TermsPage = lazy(() => import('./pages/cms/TermsPage'));
const PrizesPage = lazy(() => import('./pages/cms/PrizesPage'));
const EditorialsPage = lazy(() => import('./pages/cms/EditorialsPage'));
const CaseStudy = lazy(() => import('./pages/cms/CaseStudy'));

const EmailVerificationLandingPage = lazy(() => import('./pages/auth/EmailVerificationLandingPage'));
const ForgotPasswordPage = lazy(() => import('./pages/auth/ForgotPasswordPage'));
const LogInPage = lazy(() => import('./pages/auth/LogInPage'));
const SignUpPage = lazy(() => import('./pages/auth/SignUpPage'));
const PasswordResetLandingPage = lazy(() => import('./pages/auth/PasswordResetLandingPage'));
const PleaseVerifyEmailPage = lazy(() => import('./pages/auth/PleaseVerifyEmailPage'));

const BusinessInfoPage = lazy(() => import('./pages/business/BusinessInfoPage'));
const UserInfoPage = lazy(() => import('./pages/user/UserInfoPage'));
const UserProfilePage = lazy(() => import('./pages/user/UserProfilePage'));
const ForYouPage = lazy(() => import('./pages/user/ForYouPage'));
const UserResultsPage = lazy(() => import('./pages/user/UserResultsPage'));

const PrizeCreatePage = lazy(() => import('./pages/prizes/PrizeCreatePage'));
const PrizePage = lazy(() => import('./pages/prizes/PrizePage'));
const PrizesListPage = lazy(() => import('./pages/prizes/PrizesListPage'));
const PrizeEditPage = lazy(() => import('./pages/prizes/PrizeEditPage'));
const PrizeReportPage = lazy(() => import('./pages/prizes/PrizeReportPage'));

const EventsListPage = lazy(() => import('./pages/events/EventsListPage'));
const EventsResultsPage = lazy(() => import('./pages/events/EventsResultsPage'));
const EventPage = lazy(() => import('./pages/events/EventPage'));
const EventCreatePage = lazy(() => import('./pages/events/EventCreatePage'));
const EventSubmissionPage = lazy(() => import('./pages/events/EventSubmissionPage'));
const EventEditPage = lazy(() => import('./pages/events/EventEditPage'));
const EventReportPage = lazy(() => import('./pages/events/EventReportPage'));

const CatalogReportPage = lazy(() => import('./pages/catalog/CatalogReportPage'));
const CategoryPage = lazy(() => import('./pages/categories/CategoryPage'));
const CategoryCreatePage = lazy(() => import('./pages/categories/CategoryCreatePage'));
const CategoryEditPage = lazy(() => import('./pages/categories/CategoryEditPage'));
const CategoriesListPage = lazy(() => import('./pages/categories/CategoriesListPage'));
const CategoriesSelectPage = lazy(() => import('./pages/categories/CategoriesSelectPage'));

const BusinessReportPage = lazy(() => import('./pages/catalog/BusinessReportPage'));
const BusinessCreatePage = lazy(() => import('./pages/business/BusinessCreatePage'));
const BusinessEditPage = lazy(() => import('./pages/business/BusinessEditPage'));

export const Routes = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<>
                    <div className="vh-100 border d-flex align-items-center justify-content-center">
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </div>
                    </>}>
                    <NavBar />    
                    {/* <MDBContainer>    */}
                        <MDBRow>
                            <MDBCol>
                                <ScrollToTop>
                                    <Switch>
                                        <Route path="/user/:username">
                                            <UserProfilePage />
                                        </Route>
                                        <PrivateRoute path="/user" exact>
                                            <UserInfoPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/business" exact>
                                            <BusinessInfoPage />
                                        </PrivateRoute>
                                        <Route path="/verify-email/:verificationString">
                                            <EmailVerificationLandingPage />
                                        </Route>
                                        <Route path="/forgot-password">
                                            <ForgotPasswordPage />
                                        </Route>
                                        <Route path="/login">
                                            <LogInPage />
                                        </Route>
                                        <Route path="/reset-password/:passwordResetCode">
                                            <PasswordResetLandingPage />
                                        </Route>
                                        <Route path="/please-verify">
                                            <PleaseVerifyEmailPage />
                                        </Route>
                                        <Route path="/signup">
                                            <SignUpPage />
                                        </Route>
                                        <Route path="/about">
                                            <AboutPage />
                                        </Route>
                                        <Route path="/case-study">
                                            <CaseStudy />
                                        </Route>
                                        <Route path="/editorials">
                                            <EditorialsPage />
                                        </Route>
                                        <Route path="/prize">
                                            <PrizesPage />
                                        </Route>
                                        <Route path="/events/:eventId/report">
                                            <EventReportPage />
                                        </Route>
                                        <Route path="/events/:eventId">
                                            <EventPage />
                                        </Route>
                                        <Route path="/events">
                                            <EventsListPage />
                                        </Route>
                                        <Route path="/events-create">
                                            <EventCreatePage />
                                        </Route>
                                        <Route path="/events-submission">
                                            <EventSubmissionPage />
                                        </Route>
                                        <Route path="/events-edit/:eventId">
                                            <EventEditPage />
                                        </Route>
                                        <Route path="/search/events">
                                            <EventsResultsPage />
                                        </Route>
                                        <Route path="/prizes/:prizeCode/report">
                                            <PrizeReportPage />
                                        </Route>
                                        <Route path="/prizes/:prizeCode">
                                            <PrizePage />
                                        </Route>
                                        <Route path="/prizes">
                                            <PrizesListPage />
                                        </Route>
                                        <Route path="/prizes-create">
                                            <PrizeCreatePage />
                                        </Route>
                                        <Route path="/prizes-edit/:prizeCode">
                                            <PrizeEditPage />
                                        </Route>
                                        <Route path="/catalog">
                                            <CatalogReportPage />
                                        </Route>
                                        <Route path="/category/:categoryCode">
                                            <CategoryPage />
                                        </Route>
                                        <Route path="/category-create">
                                            <CategoryCreatePage />
                                        </Route>
                                        <Route path="/category-edit/:categoryCode">
                                            <CategoryEditPage />
                                        </Route>
                                        <Route path="/categories">
                                            <CategoriesListPage />
                                        </Route>
                                        <Route path="/categories-select">
                                            <CategoriesSelectPage />
                                        </Route>
                                        <Route path="/organizations">
                                            <BusinessReportPage />
                                        </Route>
                                        <Route path="/business-create">
                                            <BusinessCreatePage />
                                        </Route>
                                        <Route path="/business-edit/:businessCode">
                                            <BusinessEditPage />
                                        </Route>
                                        <Route path="/foryou">
                                            <ForYouPage />
                                        </Route>
                                        <Route path="/search/users">
                                            <UserResultsPage />
                                        </Route>
                                        <Route path="/terms-and-conditions">
                                            <TermsPage />
                                        </Route>
                                        <Route path="/privacy">
                                            <PrivacyPage />
                                        </Route>
                                        <Route path="/">
                                            <HomePage />
                                        </Route>
                                        <Route path="*">
                                            <NotFoundPage />
                                        </Route>
                                    </Switch> 
                                </ScrollToTop>
                            </MDBCol>
                        </MDBRow>
                    {/* </MDBContainer> */}
                </Suspense>
            </Router>
        </>
    );
}