import React from 'react';
import {createRoot} from "react-dom/client";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import { PostHogProvider} from 'posthog-js/react'
import { PrismicProvider } from '@prismicio/react'
import { client } from './prismic'

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  }

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <PostHogProvider 
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
    >
        <PrismicProvider client={client}>
            <App />
        </PrismicProvider>
    </PostHogProvider>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
